// extracted by mini-css-extract-plugin
export var Content = "blog-list-module--Content--d94de";
export var Date = "blog-list-module--Date--881c4";
export var FeaturedImage = "blog-list-module--FeaturedImage--8f1e8";
export var Image = "blog-list-module--Image--fa10b";
export var Post = "blog-list-module--Post--fc505";
export var Posts = "blog-list-module--Posts--4a49a";
export var PostsContainer = "blog-list-module--PostsContainer--04ebe";
export var Robot = "blog-list-module--Robot--de6ad";
export var Subtitle = "blog-list-module--Subtitle--c93aa";
export var Tags = "blog-list-module--Tags--4a25c";
export var Title = "blog-list-module--Title--8e497";